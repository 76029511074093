<template src="./details.html"></template>

<script>
import Http from '@/shared/http-config'
import Editor from '@tinymce/tinymce-vue';
import courses from '../Courses'
import tinymce from '@/shared/tinymce'
import DisplayWebsite from '@/shared/DisplayWebsite'
import ImageRotate from '@/shared/ImageRotate'
import moment from 'moment'
import DatePicker from 'v-calendar/lib/components/date-picker.umd'
import SocialPostForm from '@/views/marketing/media-manager/details/Form.vue'
import { permissionCheck } from '@/shared/permission.js';

export default {
  name: "Details",
  components: {'tinymceEditor': Editor, DisplayWebsite, DatePicker, ImageRotate, SocialPostForm},
  mixins: [courses, tinymce],
  data: function () {
    return {
      permissionCheck: permissionCheck,
      new_picture: false,
      course: {
        id: 0,
        name: '',
        description: '',
        date: {
          start: (new Date()),
          end: (new Date()),
        },
        city: '',
        price: 0,
        registration_period: moment().format('YYYY-MM-DD'),
        picture: null,
        category: {
          id: 2,
          name: ''
        },
        branch_id: 0,
        num_participants: 0,
        max_participants: 0,
        display_website: 1,
      },
      participants: [],
      branches: [
        {
          text: 'Alle Filialen',
          value: 0
        }
      ],
      categories: [],
      fields: [
        {
          key: 'name',
          label: 'Name',
          formatter: (value, key, item) => {
            return item.first_name + ' ' + item.last_name;
          }
        },
        {
          key: 'paid',
          label: 'Bezahlt',
          tdClass: 'text-right',
          thClass: 'text-right',
          formatter: (value, key, item) => {
            return item.first_name + ' ' + item.last_name;
          }
        },
        { key: 'actions', label: '', tdClass: 'text-right'},
      ],
      display_website: [
        {
          value: 1,
          name: 'Auto',
          icon: 'https://files.fahrschul-system.de/1_0/klasseb.png'
        },
        {
          value: 2,
          name: 'Motorrad',
          icon: 'https://files.fahrschul-system.de/1_0/klassea.png'
        },
        {
          value: 4,
          name: 'BKF',
          icon: 'https://files.fahrschul-system.de/1_0/klassec.png'
        },
      ],
    }
  },
  created: function(){
    let self = this;
    Http.request('GET', '/user/branches').then((branches) => {
      branches.data.forEach(function (branch) {
        self.branches.push({
          text: branch.name,
          value: branch.id
        });
      });
    });
    Http.request('GET', '/courses/categories').then((categories) => {
      categories.data.forEach(function (category) {
        self.categories.push({
          text: category.name,
          value: category.id
        });
      });
    });

    if(parseInt(self.$route.params.id) > 0){
      Http.request('GET', '/courses/' + self.$route.params.id).then((course) => {
        self.course = course.data;

        let parts = self.course.date.split('-');
        let parts2 = self.course.end ? self.course.end.split('-') : self.course.date.split('-');
        self.course.date = {};
        self.course.date.start = new Date(parts[0], parts[1] - 1, parts[2]);
        self.course.date.end = new Date(parts2[0], parts2[1] - 1, parts2[2]);
      });

      Http.request('GET', '/courses/' + self.$route.params.id + '/participants').then((participants) => {
        self.participants = participants.data;
      });
    }
  },
  methods: {
    onFileChange(e) {
      this.new_picture = e.target.files[0];
      this.course.picture = URL.createObjectURL(this.new_picture);
    },
    setDisplayWebsite (value){
      this.course.display_website = value;
    },
    setPaid(participant){
      let self = this;
      let text = 'Hat ' + participant.first_name + ' ' + participant.last_name + ' die Gebühr für den Kurs bezahlt?';
      if(participant.paid === true){
        text = 'Bezahlt markierung für ' + participant.first_name + ' ' + participant.last_name + ' entfernen?';
      }
      this.$bvModal.msgBoxConfirm(text, {
        headerBgVariant: 'primary',
        title: 'Bezahlt?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true) {
          let paid = participant.paid ? 0 : 1;
          Http.request('POST', '/courses/' + self.course.id + '/participants/' + participant.id + '/paid/' + paid).then(() => {
            participant.paid = paid === 1;
          });
        }
      });
    },
    deleteParticipant(participant){
      let self = this;
      this.$bvModal.msgBoxConfirm('Möchtest du ' + participant.first_name + ' ' + participant.last_name + ' wirklich vom Kurs entfernen? (Dadurch wird dieser NICHT in der Schülerdatenbank entfernt)', {
        headerBgVariant: 'primary',
        title: 'Teilnehmer entfernen?',
        okTitle: 'Ja',
        cancelTitle: 'Nein',
        cancelVariant: 'outline-danger'
      })
      .then(value => {
        if(value === true) {
          Http.request('DELETE', '/courses/' + self.course.id + '/participants/' + participant.id).then(() => {
            Http.request('GET', '/courses/' + self.course.id + '/participants').then((participants) => {
              self.participants = participants.data;
            });
          });
        }
      });
    },
    save(){
      let self = this;

      if (typeof self.$refs.socialPosts == "undefined" || self.$refs.socialPosts.validate()) {
        let formData = new FormData();
        // check if img was rotated
        self.$refs.imageRotate.getImage(true).then(function (imageRotateResponse){
          if(imageRotateResponse){
            self.new_picture = imageRotateResponse;
          }
          if(self.new_picture !== false) {
            formData.append('new_picture', self.new_picture);
          }
          formData.append('name', self.course.name);
          formData.append('description', self.course.description);
          formData.append('date', moment(self.course.date.start).format('YYYY-MM-DD'));
          formData.append('end', moment(self.course.date.end).format('YYYY-MM-DD'));
          formData.append('city', self.course.city);
          formData.append('price', self.course.price);
          if(typeof self.course.registration_period != "undefined") {
            formData.append('registration_period', self.course.registration_period);
          }
          formData.append('category[id]', self.course.category.id);
          formData.append('category[name]', self.course.category.name);
          formData.append('branch_id', self.course.branch_id);
          formData.append('num_participants', self.course.num_participants);
          formData.append('max_participants', self.course.max_participants);
          formData.append('display_website', self.course.display_website);

          Http.request('POST', '/courses/' + self.course.id, formData).then((course) => {
            if(self.$refs.socialPosts){
              self.$refs.socialPosts.store({type: 'course', data: course.data}).then(function (){
                self.$router.push('/website/courses');
              });
            }
            else {
              self.$router.push('/website/courses');
            }
          });
        });
      }
    },
  }
}
</script>

<style scoped></style>
